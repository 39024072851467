import { initializeApp } from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  signInWithCustomToken,
  signOut,
  deleteUser,
} from "firebase/auth";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import {
  getDatabase,
  connectDatabaseEmulator,
  ref,
  update,
  remove,
  get,
  push,
  query,
  orderByChild,
  equalTo,
  onValue,
  off,
} from "firebase/database";
import {
  useRef,
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { encode, decode } from "firebase-encode";
import alasql from "alasql";
import Logo from "./logo.png";
import BMCLogo from "./bmc.svg";
import { saveAs } from "file-saver";
import { Animated } from "react-animated-css";

// Utils
// --------
function mapVals(fn, _map) {
  const keys = Object.keys(_map);
  return keys.reduce((res, key) => {
    res[key] = fn(_map[key]);
    return res;
  }, {});
}

function debounce_leading(func, timeout = 300) {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

// Firebase
// --------
const firebaseConfig = {
  apiKey: "AIzaSyAo5vX3YhqdImkdgv7WG25zKwfKnSlvUuY",
  authDomain: "daily-deal-email.firebaseapp.com",
  databaseURL: "https://daily-deal-email-default-rtdb.firebaseio.com",
  projectId: "daily-deal-email",
  storageBucket: "daily-deal-email.appspot.com",
  messagingSenderId: "1063205174562",
  appId: "1:1063205174562:web:61e1887819064dfe16b97b",
  measurementId: "G-9XEK2YLL1R",
};
const app = initializeApp(firebaseConfig);

const functions = getFunctions(app);

// Uncomment for emulators
// if (window.location.hostname === "localhost") {
//   console.log("Using local functions");
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

const scrapeInstructional = httpsCallable(functions, "scrapeInstructional");
const createMagicCode = httpsCallable(functions, "createMagicCode");
const verifyMagicCode = httpsCallable(functions, "verifyMagicCode");

const auth = getAuth();

// Uncomment for emulators
// if (window.location.hostname === "localhost") {
//   console.log("Using local auth");
//   connectAuthEmulator(auth, "http://localhost:9099");
// }
//

const db = getDatabase();

// Uncomment for emulators
// if (window.location.hostname === "localhost") {
//   console.log("Using local db");
//   connectDatabaseEmulator(db, "localhost", 9000);
// }

// SQL
// -----------------------
function hydrateUserInstructionals({ userInstructionals, instructionals }) {
  return alasql(
    "SELECT i.*, ui.id, ui.createdAt FROM ? ui JOIN ? i ON ui.handle = i.handle",
    [userInstructionals, instructionals]
  );
}

// Hooks
// -----------------------
function useAuthState() {
  const [state, setState] = useState({ isLoadingUser: true, user: null });
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setState({ isLoadingUser: false, user });
    });
  }, []);
  return state;
}

function useInstructionals() {
  const [data, setData] = useState({
    isLoadingInstructionals: true,
    instructionals: null,
  });
  useEffect(() => {
    const dbRef = ref(db, "instructionals");
    const listener = onValue(dbRef, (snap) => {
      const instructionals = Object.entries(snap.val() || {}).reduce(
        (xs, [k, v]) => {
          xs[decode(k)] = mapVals(decode, v);
          return xs;
        },
        {}
      );
      setData({ isLoadingInstructionals: false, instructionals });
    });
    return () => off(listener);
  }, []);
  return data;
}

function useWatchlist({ uid, instructionals }) {
  const [data, setData] = useState({ isLoading: true, watchlist: null });
  useEffect(() => {
    const dbRef = ref(db, "user-instructionals");
    const qRef = query(dbRef, ...[orderByChild("uid"), equalTo(uid)]);
    onValue(qRef, (snap) => {
      if (!snap.val()) return setData([]);
      const userInstructionals = Object.entries(snap.val()).map(([k, v]) => ({
        id: k,
        ...v,
      }));
      if (instructionals) {
        setData({
          isLoading: false,
          watchlist: hydrateUserInstructionals({
            userInstructionals,
            instructionals: Object.values(instructionals),
          }),
        });
      }
    });
    return () => off(qRef);
  }, [uid, instructionals]);
  return data;
}

// Actions
// -----------------------
async function onCreateUserInstructional({ uid, handle }) {
  const encodedHandle = encode(handle);
  const dbRef = ref(db, "user-instructionals");
  const qRef = query(dbRef, ...[orderByChild("uid"), equalTo(uid)]);
  const exists = await get(qRef)
    .then((snap) => {
      const val = snap.val();
      return (
        val && Object.keys(val).some((k) => val[k].handle === encodedHandle)
      );
    })
    .catch((err) => {
      console.log("READ ERROR", err);
      return true;
    });

  if (exists) {
    console.log("Already added this instructional");
    return;
  }

  const res = await push(dbRef, {
    uid,
    handle: encodedHandle,
    createdAt: new Date().getTime(),
  });
  return res;
}

async function getOrScrapeInstructional({ url, instructionals }) {
  const rawPath = new URL(url).pathname;
  const handle = rawPath.substring(
    rawPath.lastIndexOf("/") + 1,
    rawPath.length
  );
  if (instructionals[[handle]]) {
    return instructionals[[handle]];
  }
  try {
    const instructional = await scrapeInstructional(handle).then(({ data }) =>
      mapVals(decode, data)
    );
    return mapVals(decode, instructional);
  } catch (_) {
    return null;
  }
}

// Validations
// -----------------------
const validHosts = [
  "bjjfanatics.com",
  "fanaticwrestling.com",
  "dynamicstriking.com",
  "judofanatics.com",
  "effectiveselfdefense.com",
];

function validateInstructionalUrl(url) {
  try {
    const parsed = new URL(url);
    const host = parsed.host.toLowerCase();
    if (!new Set(validHosts).has(host))
      return {
        msg: "Please use a fanatics url (bjjfanatics, judofanatics, etc.)",
      };
  } catch {
    return {
      msg: "Please use a fanatics url (bjjfanatics, judofanatics, etc.)",
    };
  }
}

function isValidEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Components
// -----------------------
function ActionText({ text }) {
  return (
    <p className="text-2xl text-indigo-700 font-bold text-center mb-4">
      {text}
    </p>
  );
}

function AddInstructional() {
  const user = useContext(UserContext);

  // Instructional
  const instructionalRef = useRef(null);
  const [details, setDetails] = useState(null);
  const [notifError, setNotifError] = useState(null);

  return (
    <div>
      <InstructionalForm instructionalRef={instructionalRef} cb={setDetails} />
      {details && (
        <div>
          <InstructionalPreview
            details={details}
            helpText="If this looks incorrect, double check the url or feel free to contact us for help!"
          />
          <div className="text-center">
            <RoundedButton
              label="Subscribe"
              onClick={(e) => {
                e.preventDefault();
                onCreateUserInstructional({
                  uid: user.uid,
                  handle: details.handle,
                })
                  .then((_) => {
                    setDetails(null);
                    instructionalRef.current.value = "";
                  })

                  .catch(({ message }) => {
                    setNotifError({ msg: message });
                  });
              }}
            />
            {notifError ? (
              <p className="text-red-500 mb-4">{notifError.msg}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function Watchlist({ watchlist }) {
  return (
    <div>
      <ActionText text="Your Watchlist" />
      {watchlist && watchlist.length ? (
        <div className="grid gap-8 grid-cols-2 justify-center">
          {watchlist.map((x) => (
            <Animated key={x.handle} duration={1000}>
              <div className="text-center" key={x.handle}>
                <a
                  href={`https://${x.host}.com/products/${x.handle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="transition duration-500 ease-in-out transform hover-hover:hover:scale-105"
                    src={x.thumbnail}
                    alt={x.title}
                  ></img>
                </a>
                <button
                  className="text-emerald-700 text-lg font-semibold my-4 hover-hover:hover:underline"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(ref(db, `user-instructionals/${x.id}`));
                  }}
                >
                  Unsubscribe
                </button>
              </div>
            </Animated>
          ))}
        </div>
      ) : (
        <p className="text-lg text-indigo-700 text-center mb-4">
          Nothing to see here yet!
        </p>
      )}
    </div>
  );
}

function Dashboard() {
  const user = useContext(UserContext);
  const instructionals = useContext(InstructionalsContext);
  const { uid, email } = user;
  const { isLoading, watchlist } = useWatchlist({ uid, instructionals });

  if (isLoading) {
    <div></div>;
  }

  return (
    <div>
      <h3 className="text-xl text-center mb-4">👋 Howdy {user.email}</h3>
      <Watchlist watchlist={watchlist} />
      <AddInstructional />
      <div className="p-2 mb-4">
        <div className="flex justify-center gap-8">
          <button
            className="text-xl font-semibold text-emerald-700 hover-hover:hover:underline"
            onClick={(e) => {
              e.preventDefault();
              signOut(auth);
            }}
          >
            Sign out
          </button>
          <button
            className="text-xl font-semibold text-emerald-700 hover-hover:hover:underline"
            onClick={(e) => {
              e.preventDefault();
              const ts = new Date()
                .toISOString()
                .slice(0, -5)
                .replaceAll(":", "-");
              const exportTitle = `daily-deal-export-${ts}.json`;
              const exportData = {
                user: { uid, email },
                watchlist,
              };
              const fileToSave = new Blob([JSON.stringify(exportData)], {
                type: "application/json",
                name: exportTitle,
              });
              saveAs(fileToSave, exportTitle);
            }}
          >
            Export data
          </button>
        </div>
      </div>
      <div className="outline outline-2 rounded outline-indigo-500 p-2 mb-4">
        <p className="text-2xl font-bold text-center my-2 text-indigo-500">
          Feedback?
        </p>
        <p className="text-center px-2 mb-2">
          We'd love to hear from you. Please get in touch
        </p>
        <div className="flex justify-center gap-8">
          <a
            rel="noreferrer"
            className="text-lg font-bold text-indigo-500 hover-hover:hover:underline"
            href={`https://twitter.com/intent/tweet?text=${window.encodeURIComponent(
              "@JoeAverbukh Daily Deal Email feedback:"
            )}`}
            target="_blank"
          >
            TWITTER
          </a>
          <a
            className="text-lg font-bold text-indigo-500 hover-hover:hover:underline"
            href="mailto:hello@js.ventures"
          >
            EMAIL
          </a>
        </div>
      </div>
      <button
        className="outline outline-2 rounded text-rose-700 font-bold text-center p-4 w-full hover-hover:hover:bg-rose-700 hover-hover:hover:text-white hover-hover:hover:duration-200 transition"
        onClick={(e) => {
          e.preventDefault();
          if (
            window.confirm(
              "Are you sure you want to delete your account? This cannot be undone and all your data will be deleted."
            )
          ) {
            if (watchlist) {
              const updates = watchlist.reduce((xs, x) => {
                const path = `user-instructionals/${x.id}`;
                xs[path] = null;
                return xs;
              }, {});
              update(ref(db), updates);
            }
            deleteUser(user);
            signOut(auth);
          }
        }}
      >
        Delete Account
      </button>
    </div>
  );
}

function InstructionalPreview({ details, helpText }) {
  return (
    <Animated isVisible={!!details} duration={1000}>
      <div>
        <p className="text-center text-indigo-700 font-semibold text-xl mb-4">
          {details.title}
        </p>
        <a
          href={`https://${details.host}.com/products/${details.handle}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="mx-auto mb-4 transition duration-500 ease-in-out transform hover-hover:hover:scale-105"
            src={details.thumbnail}
            alt={details.title}
            width="250px"
          ></img>
        </a>
        <p className="mb-4 text-md">{helpText}</p>
      </div>
    </Animated>
  );
}

function InstructionalForm({ instructionalRef, cb }) {
  const instructionals = useContext(InstructionalsContext);

  const [isLoadingInstructional, setIsLoadingInstructional] = useState(false);
  const [instructionalError, setInstructionalError] = useState(null);

  useEffect(() => {
    instructionalRef.current.focus();
  }, [instructionalRef]);

  const handleInstructionalError = (error) => {
    setIsLoadingInstructional(false);
    setInstructionalError(error);
    instructionalRef.current.focus();
    cb(null);
  };

  const onSubmitUrl = async (e, url) => {
    e.preventDefault();

    const error = validateInstructionalUrl(url);
    if (error) {
      handleInstructionalError(error);
      return;
    }

    // Scrape
    setInstructionalError(null);
    setIsLoadingInstructional(true);
    const instructional = await getOrScrapeInstructional({
      url,
      instructionals,
    });
    if (instructional) {
      setIsLoadingInstructional(false);
      cb(instructional);
    } else {
      handleInstructionalError({
        msg: "We couldn't find this instructional. Check the url is correct or contact us for help.",
      });
    }
  };

  return (
    <div>
      {instructionalError && (
        <Animated animationIn="headShake" duration={300}>
          <p className="text-red-500 mt-2 font-semibold">
            {instructionalError.msg}
          </p>
        </Animated>
      )}
      <form
        className="flex flex-col"
        onSubmit={(e) => onSubmitUrl(e, instructionalRef.current.value)}
      >
        <input
          ref={instructionalRef}
          className="outline outline-2 rounded outline-indigo-700 focus:outline-emerald-700 mt-2 mb-4 px-2 h-10"
          type="text"
          placeholder="https://bjjfanatics.com/products/mastering-tomoe-nage-by-shintaro-higashi"
        />
        <RoundedButton label={isLoadingInstructional ? "..." : "Search"} />
      </form>
    </div>
  );
}

function BMC() {
  return (
    <div>
      <a
        href="https://www.buymeacoffee.com/Mz4DgY0dR"
        target="_blank"
        rel="noreferrer"
      >
        <div className="fixed bottom-4 right-4 padding-4 bg-indigo-600 w-16 h-16 rounded-full transition duration-500 ease-in-out transform hover-hover:hover:scale-125 ">
          <img
            className="w-12 h-12 mx-auto my-2"
            src={BMCLogo}
            alt="Support Daily Deal Email"
          ></img>
        </div>
      </a>
    </div>
  );
}

function HomeHeader() {
  return (
    <p className="text-2xl text-indigo-700 font-bold text-center mb-4">
      Daily Deal Email is a free service that notifies you whenever an{" "}
      <a
        href="https://bjjfanatics.com/"
        target="_blank"
        rel="noreferrer"
        className="text-emerald-700 font-semibold mb-4 hover-hover:hover:underline"
      >
        instructional
      </a>{" "}
      you want is on sale.
    </p>
  );
}

function HomeFooter() {
  return (
    <>
      <ActionText text="Why Daily Deal Email?" />
      <p className="mb-4">
        Whenever you buy an instructional, you are investing in yourself and
        supporting our instructors.
      </p>
      <p className="mb-4">
        Instructionals aren’t cheap, and the costs can add up quick. On the flip
        side, producing instructionals is time consuming, and if our instructors
        aren’t compensated, they may stop producing amazing material.
      </p>
      <p className="mb-4">
        If there’s an instructional you want, you’re okay with waiting, and you
        want to support the creator, you can use Daily Deal Email to notify you
        when the instructional is on sale.
      </p>
      <p>
        <a
          href="https://www.youtube.com/watch?v=QhLsmFYCxMY"
          target="_blank"
          rel="noreferrer"
          className="text-emerald-700 font-semibold mb-4 hover-hover:hover:underline"
        >
          See how it works
        </a>
      </p>
      <p>
        <br />
        [1] Sometimes the instructional you want is listed on multiple fanatic
        websites. We'll check them all and let you know if your instructional is
        on sale. We support the following urls
        <br />
        <br />
        {validHosts.map((x) => (
          <li>{x}</li>
        ))}
        <br />
      </p>
    </>
  );
}

function AuthForm({ details, showLogin }) {
  // Email
  const emailInputRef = useRef();
  const verifyInputRef = useRef();
  const [notif, setNotif] = useState(null);
  const [given, setGiven] = useState(null);
  const [isCreatingCode, setIsCreatingCode] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    details && emailInputRef.current.focus();
  }, [details]);

  useEffect(() => {
    given && verifyInputRef.current?.focus();
  }, [given]);

  const _onSubmitEmail = (e, email) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setNotif({ type: "error", msg: "Please enter a valid email" });
      setGiven(null);
      emailInputRef.current.focus();
      return;
    }

    setIsCreatingCode(true);
    createMagicCode(email)
      .then(({ data }) => {
        console.log("Created magic code", data);
        setNotif({
          type: "info",
          msg: "Check your inbox (or spam) folder for your verification code",
        });
        setGiven(email);
      })
      .catch(({ message }) => setNotif({ type: "error", msg: message }))
      .finally(() => setIsCreatingCode(false));
  };

  const onSubmitEmail = useMemo(() => debounce_leading(_onSubmitEmail), []);

  const onSubmitCode = (e, { email, code }) => {
    e.preventDefault();
    if (!code) {
      setNotif({ type: "error", msg: "Please enter a code" });
      verifyInputRef.current.focus();
      return;
    }
    setIsVerifying(true);
    verifyMagicCode({ email, code })
      .then(({ data }) => {
        const res = signInWithCustomToken(auth, data);
        setNotif(null);
        return res;
      })
      .then(
        ({ user }) =>
          details &&
          !showLogin &&
          onCreateUserInstructional({ uid: user.uid, handle: details.handle })
      )
      .catch(({ message }) => {
        setNotif({ type: "error", msg: message });
        verifyInputRef.current.focus();
      })
      .finally(() => setIsVerifying(false));
  };

  return (
    <div className="flex flex-col">
      {notif ? (
        <Animated key={notif.msg} duration={300} isVisible={!!notif}>
          <p
            className={`font-semibold mb-2
          ${
            notif.type === "error"
              ? "text-red-500 font-semibold"
              : "text-indigo-700 font-semibold"
          }
        `}
          >
            {notif.msg}
          </p>
        </Animated>
      ) : (
        ""
      )}
      <input
        ref={emailInputRef}
        onKeyPress={(e) =>
          e.key === "Enter" && onSubmitEmail(e, emailInputRef.current.value)
        }
        className="outline outline-2 rounded outline-indigo-700 focus:outline-emerald-700 mb-4 px-2 h-10"
        type="text"
        placeholder="Enter your email..."
      ></input>
      {given && (
        <Animated duration={100} isVisible={!!given}>
          <input
            ref={verifyInputRef}
            onKeyPress={(e) =>
              e.key === "Enter" &&
              onSubmitCode(e, {
                code: verifyInputRef.current.value,
                email: given,
              })
            }
            className="outline outline-2 rounded outline-indigo-700 focus:outline-emerald-700 mb-4 px-2 h-10 w-full"
            type="text"
            placeholder="ABCDEF..."
          ></input>
        </Animated>
      )}
      {given ? (
        <RoundedButton
          label={isVerifying ? "..." : "Verify Code"}
          onClick={(e) =>
            onSubmitCode(e, {
              code: verifyInputRef.current.value,
              email: given,
            })
          }
        />
      ) : (
        <RoundedButton
          onClick={(e) => onSubmitEmail(e, emailInputRef.current.value)}
          label={
            showLogin ? (isCreatingCode ? "..." : "Send Code") : "Notify Me"
          }
        />
      )}
      {given && (
        <button
          className="text-emerald-700 font-semibold mb-4"
          onClick={(e) => {
            onSubmitEmail(e, emailInputRef.current.value);
            verifyInputRef.current?.focus() || emailInputRef.current.focus();
          }}
        >
          Resend Code
        </button>
      )}
    </div>
  );
}

function Home() {
  // Login / Sign-up
  const [showLogin, setShowLogin] = useState(false);

  // Instructional
  const instructionalRef = useRef(null);
  const [details, setDetails] = useState(null);

  if (showLogin) {
    return (
      <div>
        <HomeHeader />
        <Animated key="login" isVisible={showLogin} duration={1500}>
          <p className="mb-2">Login to your account</p>
          <AuthForm showLogin={showLogin} />
          <p className="text-center">
            Or{" "}
            <button
              className="text-emerald-700 font-semibold mb-4 hover-hover:hover:underline"
              onClick={() => setShowLogin(false)}
            >
              go back to sign-up
            </button>
          </p>
        </Animated>
        <HomeFooter />
      </div>
    );
  }

  return (
    <div>
      <HomeHeader />
      <Animated key="signUp" isVisible={!showLogin} duration={1500}>
        <p>Copy and paste a Fanatics url [1]</p>
        <InstructionalForm
          instructionalRef={instructionalRef}
          cb={setDetails}
        />
        <p className="text-center">
          Or{" "}
          <button
            className="text-emerald-700 font-semibold mb-4 hover-hover:hover:underline"
            onClick={() => setShowLogin(true)}
          >
            login to your account
          </button>
        </p>
      </Animated>
      {details && (
        <div>
          <InstructionalPreview
            details={details}
            helpText="If this looks incorrect, double check the url and re-submit. Otherwise enter your email below and we’ll send you a verification code to confirm your subscription!"
          />
          <AuthForm details={details} />
        </div>
      )}
      <HomeFooter />
    </div>
  );
}

function RoundedButton({ onClick, label }) {
  return (
    <button
      onClick={onClick}
      className="w-40 h-10 place-self-center text-xl text-white bg-indigo-700 mb-4 rounded-md transition duration-500 ease-in-out transform hover-hover:hover:scale-110"
    >
      {label}
    </button>
  );
}

function Header() {
  return (
    <Animated duration={1500}>
      <div className="flex my-2 justify-center items-center mx-auto my-4">
        <img
          key="logo"
          className="h-16 mr-3"
          src={Logo}
          alt="Daily Deal logo"
        ></img>
        <h1 className="text-3xl text-indigo-700 font-bold">Daily Deal Email</h1>
      </div>
    </Animated>
  );
}

// Contexts
// -----------------------
const UserContext = createContext(null);
const InstructionalsContext = createContext(null);

// App
// -----------------------
export default function App() {
  const { user, isLoadingUser } = useAuthState();
  const { instructionals, isLoadingInstructionals } = useInstructionals();

  if (isLoadingUser || isLoadingInstructionals) {
    return <div></div>;
  }

  return (
    <UserContext.Provider value={user}>
      <InstructionalsContext.Provider value={instructionals}>
        <div className="mx-auto max-w-lg mb-16 px-4 flex flex-col items-center">
          <Header />
          <Animated duration={1500} key={user ? "dashboard" : "home"}>
            {user ? <Dashboard /> : <Home />}
          </Animated>
          <BMC />
        </div>
      </InstructionalsContext.Provider>
    </UserContext.Provider>
  );
}
